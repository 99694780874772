import React, { useCallback, useContext, useState, useEffect } from 'react';
import { PageProps } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import {
  SEO,
  ButtonPrimary,
  NameInput,
  EmailInput,
  ButtonGoogle,
  PasswordInput,
  PasswordValidations,
  ButtonText,
  ButtonOutlined,
} from '@components';
import { navigate } from '@reach/router';
import { LayoutRegistration } from '@components/Structural/PerfilInversion';
import { AuthContext, RegisterContext } from '@context';
import { checkPasswordStrength } from '@utils';
import { ExistenceValidationType, Route } from '@interfaces';
import { useAuth } from '@hooks';
import { InvestmentsContext } from '@context';
import { APPLE_ICON } from 'images/GCP';

const SaveByRokinPage: React.FC<PageProps> = () => {
  const classes = useStyles();
  const [passInputFocused, setPassInputFocus] = useState(false);
  const [invalidEmail, setIsInvalidEmail] = useState(false);
  const { loginWithGoogle  } = useContext(AuthContext);

  const { form, handleChange, finishRegister, finishRegisterWithGoogle } =
    useContext(RegisterContext);

  const onFocus = useCallback(
    () => setPassInputFocus(true),
    [setPassInputFocus]
  );
  const onBlur = useCallback(
    () => setPassInputFocus(false),
    [setPassInputFocus]
  );

  const passwordValidations = checkPasswordStrength(form.password);
  const passwordDontMatch =
    form.passwordCheck !== '' && form.password !== form.passwordCheck;

  const isPasswordWeak =
    form.password.length > 0 &&
    Object.values(passwordValidations).some((value) => !value);

  const incompletedForm = !(form.email && form.password && form.passwordCheck);

  const disableContinueButton =
    incompletedForm || invalidEmail || passwordDontMatch || isPasswordWeak;
  return (
    <>
      <SEO />
      <LayoutRegistration>
        <section className={classes.container}>
          <div className={classes.title}>
            Bienvenido a Rokin
            <span>Regístrate para acceder a tus beneficios</span>
          </div>
          <div className={classes.form}>
            <div>
              <ButtonGoogle
                onClick={() => finishRegisterWithGoogle(false)}
                text="Registrarse con Google"
                className={`${classes.input}`}
              />
              <ButtonOutlined
                className={'className'}
                text={'Registrarse con Apple'}
                startIcon={
                  <>
                    <img src={APPLE_ICON} alt="" />
                  </>
                }
                onClick={() => {loginWithGoogle(undefined, true)} } ///HANDLE APPLE LOGIN HERE
              />
              <div className={classes.divisionForm}>
                <div className={classes.linea}></div>O
                <div className={classes.linea}></div>
              </div>
              <NameInput
                value={form.name}
                onChange={handleChange}
                label="Como te llamamos?"
                className={`${classes.input}`}
              />
              <EmailInput
                name="email"
                value={form.email}
                onChange={handleChange}
                onErrorChange={(existing) => {
                  setIsInvalidEmail(existing);
                }}
                validateExistence={ExistenceValidationType.MUST_NOT_EXISTS}
                className={`${classes.input}`}
              />
              <div className={classes.passCheck}>
                <PasswordInput
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  className={`${classes.input}`}
                />
                <PasswordValidations
                  focused={passInputFocused}
                  validations={passwordValidations}
                  className={`${classes.input}`}
                />
              </div>
              <PasswordInput
                name="passwordCheck"
                value={form.passwordCheck}
                onChange={handleChange}
                label="Repita la contraseña"
                error={passwordDontMatch}
                helperText={
                  passwordDontMatch ? 'Las contraseñas no coinciden' : ''
                }
                className={`${classes.input}`}
              />
              <div className={classes.terminos}>
                <div>Al crear tu cuenta estás aceptando los</div>
                <ButtonText
                  text="Términos y Condiciones"
                  className={`${classes.input}`}
                />
              </div>
              <ButtonPrimary
                disabled={disableContinueButton}
                onClick={() => {
                  const mailing = false; // <= cosnt that send to to the add rut paga
                  finishRegister(mailing);
                }}
                text="Registrarme"
                className={`${classes.input}`}
              />
              <ButtonText
                className={`${classes.boton2} ${classes.input}`}
                onClick={() => navigate(Route.login)}
                text="Ya tengo cuenta Rokin"
              />
            </div>
          </div>
        </section>
      </LayoutRegistration>
    </>
  );
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '40% 60%',
    [theme.breakpoints.down(1020)]: {
      justifyContent: 'center',
      gridTemplateColumns: '90%!important',
    },
  },
  input: {
    [theme.breakpoints.down(1020)]: {
      width: 330,
      maxWidth: 330,
    },
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '3rem',
    fontWeight: 'bold',
    width: 288,
    paddingLeft: '3rem',
    color: '#393e44',
    [theme.breakpoints.down(1020)]: {
      margin: '0 auto',
      width: 330,
      fontSize: 32,
      padding: 0,
      paddingBottom: '2rem',
    },
    '& span': {
      fontFamily: 'Montserrat',
      fontSize: '1.25rem',
      fontWeight: 'normal',
      display: 'block',
      color: theme.palette.primary.main,
      width: 255,
      [theme.breakpoints.down(1020)]: {
        fontSize: 14,
      },
    },
  },
  form: {
    paddingLeft: '9rem',
    [theme.breakpoints.down(1020)]: {
      paddingLeft: '0rem',
      margin: '0 auto',
    },
    '& >div': {
      display: 'grid',
      gap: 27,
      width: 344,
      [theme.breakpoints.down(1020)]: {
        width: 330,
        maxWidth: 330,
      },
    },
  },
  divisionForm: {
    display: 'flex',
    color: '#9d9d9d',
    alignItems: 'center',
    [theme.breakpoints.down(1020)]: {
      width: 300,
      maxWidth: 300,
    },
  },
  linea: {
    width: 190.5,
    height: 2,
    flexGrow: 0,
    backgroundColor: '#9d9d9d',
    margin: 14,
  },
  terminos: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.875rem',
    fontWeight: 'normal',
    display: 'block',
    color: '#545d69',
    gap: 6,
    textAlign: 'center',
    [theme.breakpoints.down(1020)]: {
      width: 300,
      maxWidth: 300,
    },
  },
  boton2: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    fontWeight: 'normal',
    display: 'block',
    color: '#545d69',
  },
  passCheck: {
    display: 'grid',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
    },
  },
}));
